import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import LocalizedLink from "../components/localizedLink"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Card from "../components/card"
import Trial from "../components/trial"
import slugify from "slugify"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"

const BlogPage = ({
  data,
  pageContext: {
    locale,
    subPages,
    tag,
    pagination_after,
    totalPosts,
    postsPerPage,
    pageNumber,
  },
  location,
}) => {
  const blogpageData = data.prismic.blogpage
  const allBlogPostsData = data.prismic.allBlogposts
  const allFeaturedBlogposts = data.prismic.allFeaturedBlogposts
  const allBlogpostsByTags = data.prismic.allBlogpostsByTags
  const allBlogpostsByPage = data.prismic.allBlogpostsByPage
  const numPages = Math.ceil(totalPosts / postsPerPage)
  const allTagsInLang = new Set([])
  if (subPages) {
    subPages
      .filter(post => post.node._meta.lang === locale)
      .map(post => post.node._meta.tags.map(tag => allTagsInLang.add(tag)))
  }

  return (
    <>
      <SEO
        title={blogpageData.meta_title}
        desc={blogpageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: blogpageData.title,
          subtitle: blogpageData.subtitle,
          image: {
            image: blogpageData.main_image,
            sharp: blogpageData.main_imageSharp,
          },
        }}
      />
      <section className="section is-medium" css={blogPageCSS}>
        <div className="container">
          <div className="columns">
            <div className="column is-6 blog__main">
              {blogpageData.main_featured_post && (
                <Card postData={{ node: blogpageData.main_featured_post }} />
              )}
            </div>
            <div className="column is-6 blog__sidebar">
              <h3 className="title is-5 has-text-weight-normal is-uppercase">
                {blogpageData.title_featured &&
                  RichText.asText(blogpageData.title_featured)}
              </h3>
              <ol style={{ listStyleType: "none" }}>
                {allFeaturedBlogposts.edges.map((featured, i) => (
                  <li key={i}>
                    <Card postData={featured} layout={"list"} />
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <div
            id="jumpToTag"
            className="columns is-multiline is-centered my-6 is-mobile"
          >
            <div key={`tagAll`} className="">
              <LocalizedLink to={`blog/#jumpToTag`}>
                <div className="tags are-medium">
                  <span
                    className={`tag is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border mx-4 my-4`}
                  >
                    <span className="icon is-size-5 ml-1 mr-2">
                      <i className={`icon-all`}></i>
                    </span>
                    All
                  </span>
                </div>
              </LocalizedLink>
            </div>
            {Array.from(allTagsInLang).map((tag, i) => {
              return (
                <div key={`tag` + i} className="">
                  <LocalizedLink to={`blog/tag/${slugify(tag)}#jumpToTag`}>
                    <div className="tags are-medium">
                      <span
                        className={`tag is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border mx-4 my-4  ${slugify(
                          tag
                        )}`}
                      >
                        <span className="icon is-size-5 ml-1 mr-2">
                          <i className={`icon-tag`}></i>
                        </span>
                        {tag}
                      </span>
                    </div>
                  </LocalizedLink>
                </div>
              )
            })}
          </div>

          {/* {allBlogpostsByPage && !tag && !pageNumber && (
            <div className="columns is-multiline">
              {allBlogPostsData.edges.map((post, i) => (
                <div key={`post` + i} className="column is-4 blog__thumbnail">
                  <Card postData={post} />
                </div>
              ))}
            </div>
          )} */}

          {// displaying post
          !tag && pageNumber && (
            <div className="columns is-multiline">
              {allBlogpostsByPage.edges.map((post, i) => (
                <div key={`post` + i} className="column is-4 blog__thumbnail">
                  <Card postData={post} />
                </div>
              ))}
            </div>
          )}

          {// displaying post when tags
          tag && !pageNumber && (
            <section className="section">
              <h3 className="title is-4">
                <span className="tag is-large">{tag}</span>
              </h3>
              <div className="columns is-multiline">
                {allBlogpostsByTags.edges.map((post, i) => (
                  <div
                    key={`postBytag` + i}
                    className="column is-4 blog__thumbnail"
                  >
                    <Card postData={post} />
                  </div>
                ))}
              </div>
            </section>
          )}

          <div className="columns is-centered">
            <div className="column blog__pagination">
              {// displaying the pagination
              !tag && pageNumber && numPages && numPages > 1 && (
                <nav
                  className="pagination is-centered is-rounded is-medium"
                  role="navigation"
                  aria-label="pagination"
                >
                  {pageNumber > 1 && ( // Left Arrow
                    <LocalizedLink
                      to={
                        pageNumber - 1 === 1
                          ? `blog/#jumpToTag`
                          : `blog/page/${pageNumber - 1}#jumpToTag`
                      }
                      className="pagination-previous blog__pagination-control"
                    >
                      <i className="icon-chevron_left"></i>
                    </LocalizedLink>
                  )}
                  {pageNumber === 1 && (
                    <div
                      className="pagination-previous blog__pagination-control"
                      style={{ opacity: 0.3 }}
                    >
                      <i className="icon-chevron_left"></i>
                    </div>
                  )}
                  {pageNumber < numPages && ( // Right Arrow
                    <LocalizedLink
                      to={`blog/page/${pageNumber + 1}#jumpToTag`}
                      className="pagination-next blog__pagination-control"
                    >
                      <i className="icon-chevron_right"></i>
                    </LocalizedLink>
                  )}
                  {pageNumber === numPages && (
                    <div
                      className="pagination-next blog__pagination-control"
                      style={{ opacity: 0.3 }}
                    >
                      <i className="icon-chevron_right"></i>
                    </div>
                  )}

                  {/* <a className="pagination-next blog__pagination-control">
                    <i className="icon-chevron_right"></i>
                  </a> */}
                  <ul className="pagination-list">
                    {[...Array(numPages)].map((e, i) => (
                      <li
                        className={
                          i + 1 === pageNumber
                            ? "pagination-link pagination-current"
                            : "pagination-link"
                        }
                      >
                        {i + 1 !== pageNumber && (
                          <LocalizedLink
                            to={
                              i + 1 === 1
                                ? `blog/#jumpToTag`
                                : `blog/page/${i + 1}#jumpToTag`
                            }
                          >
                            {i + 1}
                          </LocalizedLink>
                        )}
                        {i + 1 === pageNumber && i + 1}
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </section>
      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
      <ArrowScrollToTop />
    </>
  )
}

export default BlogPage

BlogPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const BlogPageQuery = graphql`
  query BlogPageQuery(
    $uid: String!
    $tag: [String!]
    $locale: String!
    $pagination_after: String
    $postsPerPage: Int
  ) {
    prismic {
      blogpage(uid: $uid, lang: $locale) {
        _meta {
          alternateLanguages {
            uid
            lang
          }
        }
        meta_title
        meta_description
        title
        subtitle
        title_featured
        main_featured_post {
          ... on PRISMIC_Blogpost {
            ...CardPost
          }
        }
        main_image
      }

      allFeaturedBlogposts: allBlogposts(
        lang: $locale
        sortBy: meta_firstPublicationDate_DESC
        last: 3
      ) {
        totalCount
        edges {
          node {
            ...CardPost
          }
        }
      }
      allBlogpostsByTags: allBlogposts(
        lang: $locale
        tags: $tag
        sortBy: meta_firstPublicationDate_DESC
      ) {
        totalCount
        edges {
          node {
            ...CardPost
          }
        }
      }
      allBlogpostsByPage: allBlogposts(
        lang: $locale
        first: $postsPerPage
        sortBy: meta_firstPublicationDate_DESC
        after: $pagination_after
      ) {
        totalCount
        edges {
          node {
            ...CardPost
          }
        }
      }

      ...Trial
    }

    site {
      ...SiteInformation
    }
  }
`

const blogPageCSS = css`
  .blog__pagination {
    .pagination-current {
      background-color: ${colors.blue};
      color: #fff;
    }
    .pagination.is-centered .pagination-previous {
      order: 1;
    }
    .pagination.is-centered .pagination-next {
      order: 3;
    }
    .pagination.is-centered .pagination-list {
      -webkit-box-pack: center;
      justify-content: center;
      order: 2;
    }
    .pagination-previous,
    .pagination-next {
      flex-grow: 0;
      flex-shrink: 1;
    }
    .pagination {
      justify-content: center;
    }
    .pagination-list {
      flex-grow: 0;
    }
    .pagination-link,
    .blog__pagination-control {
      -webkit-box-shadow: 0px 8px 12px 4px rgba(79, 94, 119, 0.15);
      box-shadow: 0px 8px 12px 4px rgba(79, 94, 119, 0.15);
      border-width: 0;
      .current {
      }
    }
    .pagination-link {
      a {
        width: 100%;
      }
    }
    .blog__pagination-control {
      i::before {
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#fd7543),
          to(#de4e4e)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .blog__main {
    .card {
      border-radius: 10px;
    }
    .card-image,
    .gatsby-image-wrapper {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .card-content {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom: 1px solid ${colors.blueHr};
      border-left: 1px solid ${colors.blueHr};
      border-right: 1px solid ${colors.blueHr};
    }
  }
  .blog__sidebar {
    .card {
      border-bottom: 1px solid ${colors.blueHr};
    }
    .card-content {
      padding: 0;
    }
    .media-left {
      border-radius: 10px;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
    .media-content {
      padding: 1.5rem;
    }
  }
  .blog__sidebar,
  .blog__thumbnail {
    .card {
      box-shadow: none;
    }
  }
  .blog__thumbnail {
    .card-content {
      padding: 1.5rem 0;
    }
    .card-image,
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
`
