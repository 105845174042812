import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import Facebook from "./Facebook"
import Twitter from "./Twitter"
import i18n from "../../../config/i18n"
import { transformLocale } from "../../utils/utils"

const SEO = ({
  metadata,
  title,
  desc,
  banner,
  pathname,
  article,
  locale,
  node,
  location,
}) => {
  const { defaultTitle, defaultDescription, siteLanguage, headline } = i18n[
    locale
  ]
  // prettier-ignore
  const {banner: defaultBanner,siteUrl,ogLanguage,author,twitter,facebook  } = metadata

  const localizedPath = `/${i18n[locale].path}`
  const homeURL = `${siteUrl}${localizedPath}`

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ""}`,
  }

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: defaultTitle,
    url: homeURL,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: homeURL,
    description: defaultDescription,
    author: {
      "@type": "Organization",
      name: author,
    },
    copyrightHolder: {
      "@type": "Organization",
      name: author,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Organization",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: author,
    },
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
    {
      "@type": "ListItem",
      item: {
        "@id": `${homeURL}/blog`,
        name: "blog",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Organization",
        name: author,
      },
      copyrightHolder: {
        "@type": "Organization",
        name: author,
      },
      copyrightYear: "2020",
      creator: {
        "@type": "Organization",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node._meta.firstPublicationDate,
      dateModified: node._meta.lastPublicationDate,
      description: seo.description,
      headline: seo.title,
      inLanguage: locale,
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }



  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <link hreflang={transformLocale({ locale, getOnlyFirstPart: true })} href={location.href} />
        <meta httpEquiv="content-language" content={transformLocale({ locale, getOnlyFirstPart: true })} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(breadcrumb)}
          </script>
        )}
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? "article" : "website"}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
  location: PropTypes.object,
}
