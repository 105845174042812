import React, { useEffect, useState } from 'react'
import { IoArrowUpOutline } from "react-icons/io5";
import { colors } from '../styles/styles';
import '../styles/navigation/arrowScrollToTop.scss'

const isBrowser = typeof window !== "undefined"

export const ArrowScrollToTop = () => {

    const [isTop, setIsTop] = useState(false)

    let width = 0

    if(isBrowser) {
        width = window.innerWidth;
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset === 0) {
                setIsTop(true)
            } else {
                setIsTop(false)
            }
        })
    }, [])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    }

    let iconSize = width > 375 ? 30 : 20;

    return (
        !isTop && (
            <IoArrowUpOutline size={iconSize} className={'arrow__scroll-to-top'} onClick={scrollTop} />
        )
    )
    
}