import React from "react"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import LocalizedLink from "../components/localizedLink"
import { Date } from "prismic-reactjs"

function Card({ postData, layout }) {
  const post = postData.node
  const timestamp = Date(post.post_date || post._meta.firstPublicationDate)
  const formattedTimestamp = Intl.DateTimeFormat(post._meta.lang, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(timestamp)

  return (
    <div css={cardCss}>
      <article className="card">
        {layout !== "list" && (
          <div className="card-image">
            <LocalizedLink to={`blog/${post._meta.uid}`}>
              <Image sharp={post.main_imageSharp} image={post.main_image} />
            </LocalizedLink>
          </div>
        )}
        <div className="card-content">
          <div className="media">
            {layout === "list" && (
              <div className="media-left">
                <figure className="image is-128x128">
                  <LocalizedLink to={`blog/${post._meta.uid}`}>
                    <Image
                      sharp={post.main_imageSharp}
                      image={post.main_image}
                    />
                  </LocalizedLink>
                </figure>
              </div>
            )}
            <div className="media-content">
              <div className="content">
                <p className="is-size-7">{formattedTimestamp}</p>
                <h3 className="title is-5 has-text-weight-normal no-padding">
                  <LocalizedLink to={`blog/${post._meta.uid}`}>
                    {RichText.asText(post.title)}
                  </LocalizedLink>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  )
}

export default Card

const cardCss = css``
